<template>
  <div
    v-if="isVisible"
    class="w-full max-w-[48rem] bg-dark-blue-700 p-6 md:p-9 fixed bottom-0 left-0 z-50 rounded-tr-3xl flex justify-center"
  >
    <Dialog v-if="isModalOpen" :on-accepted="handlePreferenceAcceptance" :on-dismiss="handleDismiss" />
    <div>
      <div class="text-white text-sm">
        {{ t("reason") }}
      </div>

      <div class="flex flex-row mt-6 flex-wrap">
        <ButtonWeb
          :onclick="handleAccept"
          klass="flex-1 md:flex-none md:px-14 bg-white border-solid border border-dark-blue-700 text-dark-blue-700"
        >
          {{ t("accept") }}
        </ButtonWeb>
        <ButtonWeb
          :onclick="handleRefuse"
          klass="flex-1 md:flex-none ml-4 mr-4 md:px-14 border-solid border border-white text-white"
        >
          {{ t("refuse") }}
        </ButtonWeb>

        <ButtonWeb :onclick="openPreferences" klass="flex-none text-white font-semibold !px-0">
          {{ t("advanced") }}
        </ButtonWeb>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "./Dialog";
import ButtonWeb from "~/components/Buttons/ButtonWeb";
import { isCookiePreferenceSaved, fullCookieAcceptance, refuseCookies } from "~/components/Cookie/base";

export default {
  name: "HeaderCategory",
  components: {
    ButtonWeb,
    Dialog,
  },
  data() {
    return {
      isVisible: false,
      isModalOpen: false,
    };
  },
  mounted() {
    this.isVisible = !isCookiePreferenceSaved();
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  methods: {
    handleAccept() {
      fullCookieAcceptance();
      this.isVisible = false;
    },
    handleRefuse() {
      refuseCookies();
      this.isVisible = false;
    },
    handleDismiss() {
      this.isModalOpen = false;
    },
    openPreferences() {
      this.isModalOpen = true;
    },
    handlePreferenceAcceptance() {
      this.isVisible = false;
    },
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "reason": "Nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés, et analyser notre trafic. En cliquant sur \"Accepter\", vous consentez à notre utilisation des cookies.",
    "accept": "Accepter",
    "refuse": "Refuser",
    "advanced": "Paramètres avancés"
  },
  "en": {
    "reason": "To provide the best experiences, we use technologies such as cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Failure to consent or withdrawal of consent may adversely affect certain features and functions.",
    "accept": "Accept",
    "refuse": "Refuse",
    "advanced": "Advanced parameters"
  }
}
</i18n>
