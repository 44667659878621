<template>
  <div class="rounded-lg cursor-pointer" :class="{ [`border ${borderColor}`]: hasBorder && is_open }">
    <div v-if="to">
      <nuxt-link
        tag="h3"
        :to="to"
        :active-class="openClass"
        :tabindex="aaTabIndex"
        class="flex items-center justify-between pl-8 px-5 py-4 transition duration-300 mb-px font-normal"
      >
        <span>{{ title }}</span>
        <arrow-small class="stroke-current"></arrow-small>
      </nuxt-link>
    </div>
    <div v-else>
      <button
        class="flex items-center justify-between px-5 py-4 mb-px w-full"
        :tabindex="aaTabIndex"
        @click="toggle"
        ref="btnClosed"
      >
        <slot :open="is_open" :title="title" name="title">
          <h3>{{ title }}</h3>
        </slot>
        <arrow-small class="stroke-current"></arrow-small>
      </button>
      <div
        class="absolute duration-500 w-full bg-white z-10 top-0 min-h-full overflow-y-auto overflow-x-hidden"
        :class="{ 'left-0': is_open, 'h-0 left-full': !is_open }"
      >
        <button
          class="flex items-center justify-between px-5 py-4 transition duration-500 mb-px w-full"
          :tabindex="aaTabIndex * 10"
          @click="toggle"
          ref="btnOpen"
        >
          <span class="font-medium underline">{{ t("back") }}</span>
        </button>
        <nuxt-link
          :to="titleTo"
          class="flex items-center justify-between px-5 py-4 transition duration-500 mb-px w-full"
          :class="{ [openClass]: is_open, [closedClass]: !is_open }"
          :tabindex="aaTabIndex * 10 + 1"
          ref="btnOpen"
        >
          <slot :open="is_open" :title="title" :tab-multiplier="true" name="title">
            <h3>{{ title }}</h3>
          </slot>
          <arrow-small class="transition stroke-current duration-500"></arrow-small>
        </nuxt-link>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from "~/assets/svg/arrow-small.svg";

export default {
  name: "ListStepper",
  components: {
    ArrowSmall,
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      required: false,
      default: "",
    },
    openClass: {
      type: String,
      required: false,
      default: "",
    },
    closedClass: {
      type: String,
      required: false,
      default: "bg-transparent",
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderColor: {
      type: String,
      required: false,
      default: "",
    },
    to: {
      type: String,
      required: false,
      default: "",
    },
    titleTo: {
      type: String,
      required: false,
      default: "",
    },
    aaTabIndex: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      is_open: false,
    };
  },
  watch: {
    $route() {
      this.is_open = false;
    },
    is_open(value) {
      if (value) {
        if (this.$refs.btnOpen) {
          setTimeout(() => this.$refs.btnOpen.focus(), 300);
        }
      } else {
        this.$refs.btnClosed.focus();
      }
    },
  },
  methods: {
    toggle() {
      this.is_open = !this.is_open;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-55px {
  top: 55px;
}
</style>

<i18n lang="json">
{
  "fr": {
    "back": "Retour"
  },
  "en": {
    "back": "Back"
  }
}
</i18n>
